import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#EA5455',
        secondary: '#424242', 
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#28C76F',
        warning: '#FF9F43',
        danger: '#EA5455'
      },
      color:'#626262'
    },
  },
});
