<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
  export default {
    name: 'App',
  };
</script>

<style scoped>
    >>>.v-list-item__title {
        white-space: unset
    }
    /* fix size require message under textfield */
    >>>.v-messages__message {
        font-size: 10px;
    }
    /* end all */

    /* v-file-input */
    >>>.v-input .v-label {
        height: 25px;
        line-height: 25px;
    }
    @media (min-width: 1264px) {
        .container {
            max-width: 3000px;
        }
    }
    /* v-radio-group */
    /* remove padding and margin above */
    >>>.v-input--selection-controls {
        margin-top: 0px;
        padding-top: 0px;
    }
    /* remove margin between radio and reqire message */
    >>>.v-input--selection-controls:not(.v-input--hide-details) .v-input__slot {
        margin-bottom: 0px;
    }

    /* v-select */
    /* fix icon dropdownlist */
    >>>.v-text-field.v-text-field--enclosed .v-input__append-inner {
        margin-top: 10px;
    }

    /* fix text lebel outline when use dense */
    >>>.v-text-field--outlined.v-input--dense .v-label--active {
        -webkit-transform: translateY(-24px) scale(0.75);
        transform: translateY(-24px) scale(0.75);
    }

    /* v-textarea */
    >>>.v-textarea.v-text-field--enclosed.v-text-field--outlined .v-label {
        top: 15px;
    }

    /* v-text-field */
    >>>.v-text-field {
        padding-top: 0px; /* 12px */
        margin-top: 0px; /* 4px */
    }
    /* remove margin below textfield */
    >>>.v-input__slot {
        margin-bottom: 0px;
    }
    /* fix min-height */
    >>>.v-text-field--outlined > .v-input__control > .v-input__slot {
        min-height: 44px;
    }
    /* label only outlined */
    >>>.v-text-field--outlined .v-label {
        top: 10px;
    }
    >>>.v-label.v-label--active.theme--light {
        top: 15px;
    }
    /* end label only outlined */
    >>>.v-data-table-header-mobile {
        display: none;
    }
</style>
