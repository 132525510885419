export default (to, from, next) => {
  if (localStorage.getItem('staff_id') != null) {
    // verify with firebase or jwt
    next()
  } else {
    localStorage.removeItem('staff_id')
    localStorage.removeItem('shop_id')
    localStorage.setItem('location_href_tmp_shop', window.location.href)

    next('/login')
  }
}
